<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
      if (window.location.hostname.indexOf("www") == 0) {
        window.location = window.location.href.replace("www.", "");
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
@import './has-tooltip.css';

/* @import url(//fonts.googleapis.com/css?family=Raleway:300);
#app {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: Georgia,Times,Times New Roman,serif;
  font-family: 'Boing', sans-serif;
  font-weight: 300;
} */

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 576px) {

  /* (max-width: 767px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

.photo-album {
  border-radius: 9px;
  width: 100%;
  height: 175px !important;
  object-fit: cover !important;
}

/* .................................................. */
.btn-group-xs>.btn,
.btn-xs {
  padding: .3rem .2rem;
  font-size: .575rem;
  line-height: .5;
  border-radius: .2rem;
}

/* .................................................. */
.fulljustify {
  text-align: justify;
  overflow: hidden;
}

.fulljustify:after {
  content: "";
  width: 100%;
}

@media screen and (max-width: 767px) {
  .none-fulljustify-mobile {
    text-align: start !important;
  }

  .text-center-mobile {
    text-align: center !important;
  }
}


/* .................................................. */
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

/* .................................................. */
.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
}

.fade-scale.in {
  opacity: 1;
  transform: scale(1);
}

/* .................................................. */
.vuejs-loadmore {
  height: 10px !important;
}

.incDecInput {
  font-size: 14px;
}

.text-blue {
  color: #3273dc;
}

.btn-mta {
  background: #3273dc !important;
}

.modal.show .modal-content {
  transform: none;
  border: 2px solid #024fa0;
  border-radius: 10px;
}

.swal-modal {
  border: 4px solid #024fa0;
  border-radius: 10px;
}
</style>
