<script>
import moment from 'moment'
var md5 = require('md5');

var checkMobile = (navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)) || false;

import Swal from 'sweetalert2';

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      checkMobile: checkMobile,
    }
  },
  // created(){
  //   ...
  // },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    alphabetically(array, field) {
      return array.sort(function (a, b) {
        if (a[field] < b[field]) { return -1; }
        if (a[field] > b[field]) { return 1; }
        return 0;
      })
    },
    sort_by_text_length(array) {
      return array.sort((a, b) => a.length - b.length);
    },
    set_session_userData(data) {
      let acc = {
        id: data.id,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        img: data.img,
        token: data.token
      }
      localStorage.setItem("_userData_", JSON.stringify(acc));
    },
    calculateExperience(startYear, startMonth) {
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1; // Months are zero-based

      let yearsDiff = currentYear - startYear;
      let monthsDiff = currentMonth - startMonth;

      if (monthsDiff < 0) {
        yearsDiff--;
      }

      return yearsDiff;
    },
    check_login() {
      setTimeout(() => this.loading_screen = false, 1000);
      // if(account.m_id){
      //   setTimeout(()=> this.loading_screen = false, 1000);
      //   return true;
      // }else{
      //   this.redirect("/");
      // }
    },
    md5(val) {
      return md5(val);
    },
    redirect(path, obj = null) {
      if (path == "/") {
        path = "/page/landing-page"
      } else if (this.$route.path !== path) {
        if (obj) {
          this.$router.push({ path: path, query: obj });
        } else {
          this.$router.push(path)
        }
      }
    },
    removeQueryString() {
      const newUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, newUrl);
    },
    login_with_token() {
      let tokenData = this.LocalStorage("_tokenData_");
      // let memberData = this.LocalStorage("_memberData_");
      // let accountData = this.LocalStorage("_accountData_");
      // console.log( tokenData );
      // console.log( memberData, accountData );
      if (tokenData.email && tokenData.token && tokenData.type) {
        this.login_token(tokenData);
      }
    },
    resetURLParam() {
      let url = new URL(window.location.href);
      // url.split('?')[0];
      location.replace(`${url}`);
    },
    setURLParam(topic = 0, sub = 0, content = 0) {
      let url = new URL(window.location.href);

      url = url.href.includes("?") ? url.href.split('?')[0] : url;

      if (parseInt(topic)) url += `?topic=${topic}`;
      if (parseInt(sub)) url += `&sub=${sub}`;
      if (parseInt(content)) url += `&content=${content}`;

      // alert(url);
      location.replace(`${url}`);
    },
    async login_token(tokenData) {
      // console.log(tokenData);

      var url = this.api + 'login/token/' + this.secret;
      var data = new FormData();
      data.set('email', tokenData.email);
      data.set('token', tokenData.token);
      data.set('type', tokenData.type);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            let acc = response.data.data;
            this.save_token(acc);
            switch (tokenData.type) {
              case "personal_account":
                // localStorage.setItem("_accountData_",JSON.stringify(acc));
                // this.accountData = acc;
                // this.redirect("/personal");
                this.redirect("/personal/update_profile");
                break;
              case "members":
                // localStorage.setItem("_memberData_",JSON.stringify(acc));
                // this.memberData = acc;
                this.redirect("/members");
                break;
            }
            // this.$swal("Successful Login ","","success");
            this.$emit("close");
          } else {
            this.needPassword = true;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    save_token(data) {
      let tokenData = new Object();
      tokenData.email = data.m_username;
      tokenData.m_img = data.m_img;
      tokenData.token = data.token;
      tokenData.type = data.type;
      localStorage.setItem("_tokenData_", JSON.stringify(tokenData));
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay logged in', `Logout`], dangerMode: true })
        .then((ok) => {
          if (ok) {
            this.logout();
          }
        });
    },
    logout() {
      localStorage.setItem("_userData_", JSON.stringify(new Object));
      // setTimeout(() => window.location.href = '/', 1);
      setTimeout(() => window.location.reload(), 1);
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    Verified_by_MyThaiAdvisor_front() {
      this.$swal(`Verified Company Status`, `Company search listings with a blue badge indicate that MyThaiAdvisor has verified the company’s legal registration in Thailand. Our verification process includes reviewing their official company documents to ensure authenticity and reliability. Companies with this badge have been recognized as established and legitimate businesses within our network.`, "/assets/verify.png")
    },
    Verified_by_MyThaiAdvisor() {
      Swal.fire({
        title: "This Company has been Verified by MyThaiAdvisor.",
        iconHtml: `<img src="/assets/verify.png">`,
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top-end',
        customClass: {
          popup: 'verified-company-popup',
        },
      });
    },
  },
  filters: {
    // moment(date) {
    //   return moment(date).format('Do MMMM YYYY');
    // },
    // date_message: function (date) {
    //   return moment(date).format('D MMM, YYYY h:mm a');
    // },
    // date_project: function (date) {
    //   return moment(date).format('MMMM Do, YYYY h:mm a')+" (PST)";
    // },
    // date_list: function (date) {
    //   var fromNow = moment( date ).fromNow();
    //   return moment( date ).calendar( null, {
    //       lastWeek: '[Last] dddd',
    //       lastDay:  '[Yesterday]',
    //       sameDay:  '[Today]',
    //       nextDay:  '[Tomorrow]',
    //       nextWeek: 'dddd',
    //       sameElse: function () {
    //           return "[" + fromNow + "]";
    //       }
    //   });
    // },
    member_since(date) {
      return moment(date).format('D/MM/YYYY');
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>

<style>
.swal-icon--custom {
  width: 75px !important;
}

.verified-company-popup .swal2-icon {
  border: 0px !important;
  margin: 0px !important;
  width: 45px !important;
}
</style>