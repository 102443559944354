import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: lazyLoad("Index")
  // },
  {
    path: '/',
    // redirect: "/page/",
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppPage',
        component: lazyLoad("App/Page")
      },
    ]
  },
  // {
  //   path: '/app',
  //   component: lazyLoad("App/Base"),
  //   children: [
  //       // {
  //       //   path: '',
  //       //   name: 'AppLandingPage',
  //       //   component: lazyLoad("App/LandingPage")
  //       // },
  //       {
  //         path: 'page/:page',
  //         name: 'AppPage',
  //         component: lazyLoad("App/Page")
  //       },
  //       // {
  //       //   path: 'living_expense_calculator',
  //       //   name: 'AppLivingExpenseCalculator',
  //       //   component: lazyLoad("App/LivingExpenseCalculator")
  //       // },
  //     ]
  // },
  {
    path: '/page/',
    component: lazyLoad("App/Base"),
    // redirect: "/page/landing-page",
    children: [
      {
        path: ':page',
        name: 'AppPage',
        component: lazyLoad("App/Page")
      },
    ]
  },
  {
    path: '/article_list',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'ArticleList',
        component: lazyLoad("App/ArticleList")
      },
    ]
  },
  {
    path: '/living_expense_calculator',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppLivingExpenseCalculator',
        component: lazyLoad("App/LivingExpenseCalculator")
      },
    ]
  },
  // {
  //   path: '/medical_expense_calculator',
  //   component: lazyLoad("App/Base"),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'AppMedicalExpenseCalculator',
  //       component: lazyLoad("App/MedicalExpenseCalculator")
  //     },
  //   ]
  // },
  {
    path: '/dental_expense_calculator',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppDentalExpenseCalculator',
        component: lazyLoad("App/DentalExpenseCalculator")
      },
    ]
  },
  {
    path: '/housing_transportation_calculator',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppHousingTransportationCalculator',
        component: lazyLoad("App/HousingTransportationCalculator")
      },
    ]
  },
  {
    path: '/massage_spa_treatment',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppMassageSpaTreatmentCalculator',
        component: lazyLoad("App/MassageSpaTreatmentCalculator")
      },
    ]
  },
  {
    path: '/search_tourism',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppSearchTourism',
        component: lazyLoad("App/SearchTourism")
      },
      {
        path: ':c_code',
        name: 'AppTourism',
        component: lazyLoad("App/Company")
      },
    ]
  },
  {
    path: '/search_legal',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppSearchLegal',
        component: lazyLoad("App/SearchLegal")
      },
      {
        path: ':c_code',
        name: 'AppLegalServices',
        component: lazyLoad("App/Company")
      },
    ]
  },
  {
    path: '/search_educational',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppSearchEducational',
        component: lazyLoad("App/SearchEducational")
      },
      {
        path: ':c_code',
        name: 'AppEducational',
        component: lazyLoad("App/Company")
      },
    ]
  },
  // -------------- Special Company ---------------
  {
    path: '/search_company',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppSearchSpecialCompany',
        component: lazyLoad("App/SearchSpecialCompany")
      },
      {
        path: ':c_code',
        name: 'AppSpecialCompany',
        component: lazyLoad("App/Company")
      },
    ]
  },
  {
    path: '/advance',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: ':table/:id',
        name: 'AppAdvanceStorage',
        component: lazyLoad("App/Company")
      },
    ]
  },
  {
    path: '/article_subscriber',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'ArticleSubscriber',
        component: lazyLoad("App/ArticleSubscriber")
      },
      {
        path: ':pdf',
        name: 'ArticleSubscriber',
        component: lazyLoad("App/ArticleSubscriber")
      },
    ]
  },
  {
    path: '/pricing',
    component: lazyLoad("App/Base"),
    children: [
      {
        path: '/',
        name: 'AppPricing',
        component: lazyLoad("App/Pricing")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
