import Vue from 'vue'
import App from './App.vue'

import MainFunc from './MainFunc.vue'
Vue.mixin(MainFunc);

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   config: { id: "G-HCF3HRY3C0" }
// });

// import Paypal_plan from './Paypal_plan.vue'
// Vue.mixin(Paypal_plan);

import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "G-4FC2L8SPNN",
  router
});

import Meta from 'vue-meta'
Vue.use(Meta)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
})

import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// import './../node_modules/bulma/css/bulma.css';
// import './../node_modules/bootstrap-grid-only-css/dist/css/bootstrap-grid.min.css';
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/assets/lazy.png',
  error: '/assets/lazy.png',
  // error: '/assets/user.jpg',
  attempt: 1
})

// import VueLazyLoadVideo from 'vue-lazyload-video'
// Vue.use(VueLazyLoadVideo)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

// import VueMasonry from 'vue-masonry-css'
// Vue.use(VueMasonry);

// import { VueMasonryPlugin } from 'vue-masonry';
// Vue.use(VueMasonryPlugin)

// import MasonryWall from '@yeger/vue2-masonry-wall'
// Vue.use(MasonryWall)

// import VueWMasonryGallery from "vue-masonry-gallery";
// Vue.use(VueWMasonryGallery)

// import VueViewer from 'v-viewer'
// Vue.use(VueViewer)
// import 'viewerjs/dist/viewer.css'

// import VueCarousel from 'vue-carousel';
// Vue.use(VueCarousel);

import VueLoadmore from 'vuejs-loadmore';
Vue.use(VueLoadmore);

// import VuePdfApp from "vue-pdf-app";
// Vue.component("vue-pdf-app", VuePdfApp);
// import "vue-pdf-app/dist/icons/main.css";

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
